import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    height: "90%",
    marginTop: "0px",
  },
  listContainer: {
    border: "1px solid #000000",
    borderRadius: "5px",
  },
  listItemBtn: {
    borderBottom: "1px solid black",
    transition: "none !important",
    "&:hover": {
      backgroundColor: "#BBBBBB",
    },
    padding: "8px",
  },
  listItmBtnSelected: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#BBBBBB",
    },
  },
  listIcon: {
    minWidth: "45px",
  },
  tableRowHeadService: {
    width: "20%",
    padding: "8px",
  },
  tableRowCells: {
    fontSize: "16px",
  },
  tableRowCellsColor: {
    color: "#666666 !important",
  },
  tableRowHeadInvitation: {
    width: "25%",
  },
}));

export default useStyles;
