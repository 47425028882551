import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { User, Trash } from "phosphor-react";
import useStyles from "../../NewOrganizationsOverview/NewOrganizationsOverview.styles";
import { fdelete, fget } from "../../../../../API/callsApi";
import CommonLoader from "../../../../ReusableComponents/CommonLoader/CommonLoader";
import CustomPagination from "../../../../ReusableComponents/CustomPagination/CustomPagination";
import CharacterWithDialogue from "../../../../ReusableComponents/Svgs/CharacterWithDialogue";
import { toast } from "react-hot-toast";

const InvitationsTable = ({
  currentOrganization,
  userSearchValue,
  refreshCounter,
}) => {
  const classes = useStyles();
  const [allInvitedUsers, setAllInvitedUsers] = useState({
    count: 0,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const getInvitedUser = async () => {
    setIsLoading(true);
    const url = `/organization/${currentOrganization}/sent-invites/?status=${"Pending"}&page=${currentPage}`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setAllInvitedUsers({
          count: res.data.count,
          data: res.data.results,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const deleteInvite = async (inviteId) => {
    try {
      const res = await fdelete({
        url: `/organization/${currentOrganization}/delete-invite/${inviteId}/`,
      });
      if (res.status === 200 || res.status === 204) {
        toast.success("Invitation deleted");
        handlePageChange(1);
      }
    } catch (error) {
      toast.error("Couldn't delete invitation");
      console.log(error);
    }
  };

  useEffect(() => {
    handlePageChange(1);
  }, [refreshCounter]);

  useEffect(() => {
    getInvitedUser();
  }, [currentOrganization, updateOnPageChange]);

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <CommonLoader />
        </div>
      ) : allInvitedUsers && allInvitedUsers.data.length > 0 ? (
        <Box sx={{ height: "100%", width: "100%" }}>
          <TableContainer
            style={{
              height: "6%",
              overflow: "hidden",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                    sx={{ width: "18% !important" }}
                  >
                    User
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                    sx={{ width: "18% !important" }}
                  >
                    User Role
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                    sx={{ width: "28% !important" }}
                  >
                    Email ID
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                    sx={{ width: "20% !important" }}
                  >
                    Details
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                    sx={{ width: "16% !important" }}
                  >
                    <span style={{ marginRight: "40px" }}> Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box
            style={{
              height: allInvitedUsers.count > 50 ? "80%" : "87%",
              overflowY: "auto",
              border: "1px solid #666666",
              borderRadius: "5px",
            }}
          >
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody style={{ height: "100%" }}>
                  {allInvitedUsers.data
                    ?.filter((search) => {
                      if (userSearchValue === undefined) {
                        return search;
                      } else if (userSearchValue === "") {
                        return search;
                      } else if (
                        search?.user?.first_name
                          ?.toLowerCase()
                          .includes(userSearchValue.toLowerCase())
                      ) {
                        return search;
                      } else if (
                        search?.user?.last_name
                          ?.toLowerCase()
                          .includes(userSearchValue.toLowerCase())
                      ) {
                        return search;
                      }
                      return null;
                    })
                    .map((eachUser) => (
                      <TableRow
                        key={eachUser.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                          sx={{ width: "18% !important" }}
                        >
                          <Typography variant="h5" fontWeight={500}>
                            {eachUser.user?.first_name
                              ? `${eachUser.user?.first_name} ${eachUser.user?.last_name}`
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                          sx={{ width: "18% !important" }}
                        >
                          <Typography
                            variant="h5"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            <User />{" "}
                            {eachUser.user?.role ? eachUser.user?.role : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                          sx={{ width: "28% !important" }}
                        >
                          <Typography variant="h5">
                            {eachUser.user_email ? eachUser.user_email : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                          sx={{ width: "20% !important" }}
                        >
                          <Typography variant="h5">
                            by {eachUser.sent_by.first_name}{" "}
                            {eachUser.sent_by.last_name}
                            <br />
                            on{" "}
                            {new Date(eachUser.sent_date).toLocaleDateString(
                              "en-US",
                              {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                          sx={{ width: "16% !important" }}
                        >
                          <Button
                            style={{
                              margin: "0px",
                              padding: "5px",
                              minWidth: "0px",
                              textTransform: "none",
                              marginRight: "40px",
                            }}
                            onClick={() => deleteInvite(eachUser.id)}
                          >
                            <Trash size={20} color="#E01717" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {allInvitedUsers.count > 50 && (
            <footer style={{ width: "100%", position: "relative" }}>
              <CustomPagination
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalItem={allInvitedUsers?.count}
                totalPage={
                  allInvitedUsers ? Math.ceil(allInvitedUsers?.count / 50) : 0
                }
                type="Invitations Users"
              />
            </footer>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={15}
        >
          <Box>
            <CharacterWithDialogue />
            <Box sx={{ marginLeft: "220px", marginTop: "-150px" }}>
              <Typography variant="h5" textAlign="left">
                The invitation list is empty!
              </Typography>
              <Typography variant="h5" textAlign="left" mt={2}>
                Time to invite some users
                <br /> to your party!
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default InvitationsTable;
