import {
  Box,
  Button,
  Typography,
  Grid,
  Divider,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  CircularProgress,
} from "@mui/material";
import { Info, X, Minus, Plus, ArrowUpRight } from "phosphor-react";
import React, { useState, useEffect } from "react";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import MovieColabIcon from "../../ReusableComponents/Svgs/MovieColabIcon";
import { fget, fpost } from "../../../API/callsApi";
import useAuth from "../../../hooks/useAuth";
import CheckCircleSvg from "../../ReusableComponents/Svgs/CheckCircleSvg";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";

const AddLicensesModal = ({
  close,
  activeLicenseCount,
  availableLicenseCount,
  subscriptionExists,
}) => {
  const params = useParams();
  const { currentOrg } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPricePlan, setSelectedPricePlan] = useState("monthly");
  const [monthlyPricePlan, setMonthlyPricePlan] = useState({
    type: "monthly",
    priceId: "",
    amount: "",
    totalNumber: 0,
  });
  const [yearlyPricePlan, setYearlyPricePlan] = useState({
    type: "yearly",
    priceId: "",
    amount: "",
    totalNumber: 0,
  });

  const getStripeProducts = async () => {
    setIsLoading(true);
    try {
      const res = await fget({
        url: "/stripe/product/",
      });
      if (res.status === 200 || res.status === 201) {
        const filtered = res.data.data.filter(
          (eachProduct) => eachProduct.metadata.service === "MovieColab"
        );
        if (filtered.length > 0 && filtered[0]?.prices?.data.length > 0) {
          filtered[0]?.prices?.data.forEach((pricePlan) => {
            if (pricePlan.lookup_key === "yearly_license_subscription") {
              setYearlyPricePlan({
                type: "yearly",
                priceId: pricePlan.id,
                amount: pricePlan.unit_amount,
                totalNumber: 0,
              });
            } else if (
              pricePlan.lookup_key === "monthly_license_subscription"
            ) {
              setMonthlyPricePlan({
                type: "monthly",
                priceId: pricePlan.id,
                amount: pricePlan.unit_amount,
                totalNumber: 0,
              });
            }
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, currentOrg);
      setIsLoading(false);
    }
  };

  const updateSubscription = async () => {
    const obj = {
      subscription_id:
        selectedPricePlan === "monthly"
          ? subscriptionExists.monthly_subscription
          : subscriptionExists.yearly_subscription,
      quantity:
        selectedPricePlan === "monthly"
          ? monthlyPricePlan.totalNumber
          : yearlyPricePlan.totalNumber,
    };
    try {
      const res = await fpost({
        url: "/purchase-additional-licenses",
        data: obj,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("subscription updated");
        close();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data[0]);
    }
  };

  useEffect(() => {
    getStripeProducts();
  }, []);

  return (
    <Box
      sx={{
        width: "1300px",
        borderRadius: "10px",
        padding: "24px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "600px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h2" fontWeight={600}>
              Adding License/s
            </Typography>
            <Button onClick={close}>
              <X color="#000000" size={30} />
            </Button>
          </Box>
          <Typography variant="h6" sx={{ marginTop: "12px" }}>
            New licenses will be added immediately, and the cost for the
            remaining time, along with the yearly subscription for those
            licenses, will be included in your next renewal amount.
          </Typography>
          <Box
            mt={4}
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Box sx={{ width: "60%" }}>
              <Typography variant="h4" fontWeight={600}>
                Choose a License type
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  margin: "12px 0px",
                }}
              >
                <Info color="#666666" size={20} />
                <Typography color="#666666" variant="h6">
                  You have {availableLicenseCount} available license (licenses
                  with no assigned user)
                </Typography>
              </div>
              <FormControl>
                <RadioGroup
                  value={selectedPricePlan}
                  onChange={(event) => setSelectedPricePlan(event.target.value)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Radio />}
                        value="yearly"
                        label="Yearly License"
                        disabled={
                          subscriptionExists.yearly_subscription === null
                        }
                      />
                      {subscriptionExists.yearly_subscription === null && (
                        <Link
                          to={`/organizations/${params.orgId}/services/${params.sid}/payment`}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#FF9500",
                              gap: "3px",
                            }}
                          >
                            Buy Now
                            <ArrowUpRight size={16} />
                          </Typography>
                        </Link>
                      )}
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        control={<Radio />}
                        value="monthly"
                        label="Monthly License"
                        disabled={
                          subscriptionExists.monthly_subscription === null
                        }
                      />
                      {subscriptionExists.monthly_subscription === null && (
                        <Link
                          to={`/organizations/${params.orgId}/services/${params.sid}/payment`}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#FF9500",
                              gap: "3px",
                            }}
                          >
                            Buy Now
                            <ArrowUpRight size={16} />
                          </Typography>
                        </Link>
                      )}
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  borderRadius: "10px",
                  border: "1px solid #000000",
                  width: "500px",
                  margin: "20px 0px",
                }}
              >
                <Box
                  p={3}
                  sx={{
                    width: "50%",
                    background:
                      "radial-gradient(200% 100% at 50% 100%,  #FFFFFF 0%,  #BFFFB7 15%,  #63B4FF 40%, #564ECA 60%, #232058 75%, #101013 100%)",
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight={600}
                    textAlign="center"
                    color="#FFFFFF"
                  >
                    {selectedPricePlan === "yearly" &&
                      `Rs. ${yearlyPricePlan.amount}/yr`}
                    {selectedPricePlan === "monthly" &&
                      `Rs. ${monthlyPricePlan.amount}/mo`}
                  </Typography>
                  <Box
                    sx={{
                      padding: "12px 24px",
                      borderRadius: "5px",
                      backgroundColor: "#FFFFFF",
                      backdropFilter: "blur(4px)",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={500}
                      textAlign="center"
                    >
                      {selectedPricePlan === "monthly" && "Monthly License"}
                      {selectedPricePlan === "yearly" && "Yearly License"}
                    </Typography>
                  </Box>
                </Box>
                <Box p={3} sx={{ width: "50%", border: "1px solid green" }}>
                  <Typography fontWeight={500} variant="h5" textAlign="center">
                    Choose the number of new{" "}
                    {selectedPricePlan === "yearly"
                      ? "yearly licenses"
                      : "monthly licenses"}
                    <span style={{ color: "#E01717" }}>*</span>
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "28px",
                      marginTop: "12px",
                    }}
                  >
                    <div style={{ width: "32px" }}>
                      <FilledButton
                        disabled={
                          (yearlyPricePlan.totalNumber < 1 &&
                            selectedPricePlan === "yearly") ||
                          (monthlyPricePlan.totalNumber < 1 &&
                            selectedPricePlan === "monthly")
                        }
                        small={true}
                        text={<Minus />}
                        submit={() => {
                          if (selectedPricePlan === "yearly") {
                            setYearlyPricePlan({
                              ...yearlyPricePlan,
                              totalNumber: yearlyPricePlan.totalNumber - 1,
                            });
                          } else if (selectedPricePlan === "monthly") {
                            setMonthlyPricePlan({
                              ...monthlyPricePlan,
                              totalNumber: monthlyPricePlan.totalNumber - 1,
                            });
                          }
                        }}
                      />
                    </div>
                    <Typography variant="h4">
                      {selectedPricePlan === "monthly" &&
                        monthlyPricePlan.totalNumber}
                      {selectedPricePlan === "yearly" &&
                        yearlyPricePlan.totalNumber}
                    </Typography>
                    <div style={{ width: "32px" }}>
                      <FilledButton
                        small={true}
                        text={<Plus />}
                        submit={() => {
                          if (selectedPricePlan === "yearly") {
                            setYearlyPricePlan({
                              ...yearlyPricePlan,
                              totalNumber: yearlyPricePlan.totalNumber + 1,
                            });
                          } else if (selectedPricePlan === "monthly") {
                            setMonthlyPricePlan({
                              ...monthlyPricePlan,
                              totalNumber: monthlyPricePlan.totalNumber + 1,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </Box>
              </div>
              <div style={{ display: "flex", gap: "6px" }}>
                <CheckCircleSvg small={true} />
                <Typography variant="h5">
                  1 Movie Colab Account per license (valid for{" "}
                  <span style={{ fontWeight: "500" }}>
                    {selectedPricePlan === "monthly" ? "1 month" : "1 year"}
                  </span>
                  )
                </Typography>
              </div>
              <Typography
                sx={{ marginLeft: "26px" }}
                variant="h6"
                color="#666666"
              >
                Get a dedicated Movie Colab account that provides full access to
                all collaboration tools, personalized settings, and project
                management features tailored to your workflow.
              </Typography>

              <div style={{ display: "flex", gap: "6px", margin: "16px 0px" }}>
                <CheckCircleSvg small={true} />
                <Typography variant="h5">Movie Colab Web & Desktop</Typography>
              </div>
              <div style={{ display: "flex", gap: "6px" }}>
                <CheckCircleSvg small={true} />
                <Typography variant="h5">24/7 Customer Support</Typography>
              </div>
            </Box>
            <Box sx={{ width: "40%" }}>
              <Box
                py={4}
                px={6}
                style={{ border: "1px solid #BBBBBB", borderRadius: "20px" }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <MovieColabIcon />
                  <Typography variant="h1" fontWeight={600}>
                    Movie Colab
                  </Typography>
                </div>
                <Grid container sx={{ margin: "48px 0px 12px 0px" }}>
                  <Grid item xs={6}>
                    <Typography variant="h5" color="#666666">
                      Active Licenses
                    </Typography>
                  </Grid>
                  <Grid item xs={1} textAlign="center">
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h5" textAlign="left">
                      {activeLicenseCount}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ margin: "12px 0px" }}>
                  <Grid item xs={6}>
                    <Typography variant="h5" color="#666666">
                      Available Licenses
                    </Typography>
                  </Grid>
                  <Grid item xs={1} textAlign="center">
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h5" textAlign="left">
                      {availableLicenseCount}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ margin: "12px 0px 0px 0px" }}>
                  <Grid item xs={6}>
                    <Typography variant="h5" color="#666666">
                      New {selectedPricePlan} Licenses (+)
                    </Typography>
                  </Grid>
                  <Grid item xs={1} textAlign="center">
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h5" textAlign="left">
                      {selectedPricePlan === "monthly"
                        ? monthlyPricePlan.totalNumber
                        : selectedPricePlan === "yearly"
                        ? yearlyPricePlan.totalNumber
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ margin: "24px 0px" }} />

                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h5" color="#666666">
                      New Total Licenses
                    </Typography>
                  </Grid>
                  <Grid item xs={1} textAlign="center">
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h5" textAlign="left">
                      {activeLicenseCount +
                        availableLicenseCount +
                        (selectedPricePlan === "monthly"
                          ? monthlyPricePlan.totalNumber
                          : selectedPricePlan === "yearly"
                          ? yearlyPricePlan.totalNumber
                          : 0)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ margin: "20px 0px" }} />
                {/* <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5" color="#666666">
                  Next Renewal Amount
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign="center">
                <Typography>:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h3" textAlign="left">
                  Rs. 1,50,000
                </Typography>
              </Grid>
            </Grid> */}
                <Typography
                  variant="h5"
                  color="#666666"
                  sx={{ margin: "6px 0px 48px 0px" }}
                >
                  To be paid on next renewal date (29th Oct, 2025)
                </Typography>
                <div style={{ width: "264px" }}>
                  <FilledButton
                    text="Confirm & Add new licenses"
                    disabled={
                      monthlyPricePlan.totalNumber === 0 &&
                      yearlyPricePlan.totalNumber === 0
                    }
                    submit={updateSubscription}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default AddLicensesModal;
