import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  filledBtn: {
    minWidth: "0px",
    width: "100%",
    backgroundColor: "#000000 !important",
    color: "#FFFFFF !important",
    borderRadius: "5px",
    padding: "10px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#262626 !important",
    },
  },
  redFilledBtn: {
    width: "100%",
    backgroundColor: "#E01717 !important",
    color: "#FFFFFF !important",
    borderRadius: "5px",
    padding: "10px 20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#E85151 !important",
    },
  },
  outlinedBtn: {
    width: "100%",
    backgroundColor: "#FFFFFF 1important",
    color: "#000000 !important",
    border: "2px solid #000000",
    borderRadius: "5px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#D9D9D9 !important",
    },
  },
});

export default useStyles;
