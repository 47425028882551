import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { CaretLeft } from "phosphor-react";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import useAuth from "../../../hooks/useAuth";
import { fpost } from "../../../API/callsApi";
import toast from "react-hot-toast";
import SetupServiceOrgRequired from "./SetupServiceOrgRequired";
import MovieColabIcon from "../../ReusableComponents/Svgs/MovieColabIcon";

const SetupServiceOverview = () => {
  const params = useParams();
  const history = useHistory();
  const { adminOrgs } = useAuth();
  const [selectedOrg, setSelectedOrg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const requestService = async () => {
    setIsLoading(true);
    try {
      const res = await fpost({
        url: "/request-service/",
        data: {
          service_product: params.sid,
          organization: selectedOrg,
        },
      });
      if (res.status === 200 || res.status === 201) {
        setIsLoading(false);
        //add modal or toast message
        toast.success("Service requested successfully");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data[0]);
      } else {
        toast.error(error.message);
      }
    }
  };

  if (adminOrgs.length <= 0) {
    return <SetupServiceOrgRequired />;
  }

  return (
    <Box boxSizing="border-box" px={8} py={3} style={{ height: "100%" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div>
          <FilledButton
            text={<CaretLeft size={20} />}
            submit={() => history.push("/services")}
          />
        </div>
        <Typography variant="h5">Explore Services</Typography>
      </div>
      <Grid container mt={3} gap={6}>
        <Grid item xs={3}>
          <Typography mb={3} variant="h1" fontWeight={600}>
            Org & Service Details
          </Typography>
          <FormControl size="small" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label-org">
              Organization
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label-org"
              id="demo-simple-select-helper-label-org"
              variant="outlined"
              label="Organization"
              style={{ width: "100%" }}
              value={selectedOrg}
              onChange={(event) => setSelectedOrg(event.target.value)}
            >
              {adminOrgs.map((eachOrg) => {
                return (
                  <MenuItem value={eachOrg.id} key={eachOrg.id}>
                    {eachOrg.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Typography mt={3} mb={3}>
            Don’t see your organization? Contact support.
          </Typography>
          <Box
            mb={3}
            style={{
              width: "100%",
              boxSizing: "border-box",
              border: "1px solid #000000",
              borderRadius: "5px",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <MovieColabIcon small={true} />
            <Typography fontWeight={500} variant="h5">
              Movie Colab
            </Typography>
          </Box>
          <div style={{ width: "250px" }}>
            <FilledButton
              disabled={isLoading || !selectedOrg}
              text="Request Service Setup"
              submit={() => requestService()}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h1" fontWeight={600}>
            How does Setup Service work?
          </Typography>
          <Typography mt={3} mb={3} variant="h5">
            Once your request is submitted, we&apos;ll send you an email when
            your request for service setup was completed. You can also view your
            request status directly from your organization&apos;s page, ensuring
            that you&apos;re always up-to-date on the status of your request.
          </Typography>
          <Typography mt={3} mb={3} variant="h5">
            Once your service is setup, you can purchase and add licenses under{" "}
            <span style={{ fontWeight: "600" }}>Buy & Manage Licenses</span> in
            your <span style={{ fontWeight: "600" }}>Organization.</span>
          </Typography>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "5px",
                backgroundColor: "#000000",
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              1
            </Box>
            <div style={{ borderBottom: "1px solid black", width: "100px" }} />
            <Box
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "5px",
                backgroundColor: "#666666",
                textAlign: "center",
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              2
            </Box>
            <div style={{ borderBottom: "1px solid black", width: "100px" }} />
            <Box
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "5px",
                backgroundColor: "#666666",
                textAlign: "center",
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              3
            </Box>
            <div style={{ borderBottom: "1px solid black", width: "100px" }} />
            <Box
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "5px",
                backgroundColor: "#666666",
                textAlign: "center",
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              4
            </Box>
          </Box>
          <Box
            mt={1}
            style={{ display: "flex", alignItems: "center", gap: "40px" }}
          >
            <Typography variant="h5" width={100}>
              Service Setup Request
            </Typography>
            <Typography variant="h5" width={100}>
              Service Deployment
            </Typography>
            <Typography variant="h5" width={100}>
              Purchasing License
            </Typography>
            <Typography variant="h5" width={100}>
              Assigning License to a User
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetupServiceOverview;
