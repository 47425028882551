import React from "react";

const PluginIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 5.5V4.66667C15.8333 4.22464 15.6577 3.80072 15.3452 3.48816C15.0326 3.17559 14.6087 3 14.1667 3H12.5C12.058 3 11.634 3.17559 11.3215 3.48816C11.0089 3.80072 10.8333 4.22464 10.8333 4.66667V5.5H9.16667V4.66667C9.16667 4.22464 8.99107 3.80072 8.67851 3.48816C8.36595 3.17559 7.94203 3 7.5 3H5.83333C5.39131 3 4.96738 3.17559 4.65482 3.48816C4.34226 3.80072 4.16667 4.22464 4.16667 4.66667V5.5H2.5V17.1667H11.6667V15.5H4.16667V7.16667H15.8333V11.3333H17.5V5.5M17.5 13V15.5H20V17.1667H17.5V19.6667H15.8333V17.1667H13.3333V15.5H15.8333V13H17.5Z"
        fill="black"
      />
    </svg>
  );
};

export default PluginIcon;
