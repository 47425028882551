import React from "react";

const MessageIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9993 12.167H8.74935L10.416 10.5003H14.9993M4.99935 12.167V10.0837L10.7327 4.36699C10.891 4.20866 11.1577 4.20866 11.3243 4.36699L12.791 5.84199C12.9577 6.00866 12.9577 6.26699 12.791 6.43366L7.05768 12.167M16.666 2.16699H3.33268C2.89065 2.16699 2.46673 2.34259 2.15417 2.65515C1.84161 2.96771 1.66602 3.39163 1.66602 3.83366V18.8337L4.99935 15.5003H16.666C17.108 15.5003 17.532 15.3247 17.8445 15.0122C18.1571 14.6996 18.3327 14.2757 18.3327 13.8337V3.83366C18.3327 2.90866 17.5827 2.16699 16.666 2.16699Z"
        fill="black"
      />
    </svg>
  );
};

export default MessageIcon;
