import React from "react";
import { Route } from "react-router-dom";
import NewLogin from "./components/Authentications/NewLogin/NewLogin";
import SideBannerLayout from "./components/SideBarAndNavbar/SideBannerLayout";

const RouteRequiresLogin = (props) => {
  let userIsLogged = false;
  let data = localStorage.getItem("refresh");
  data ? (userIsLogged = true) : (userIsLogged = false);

  return (
    <Route {...props}>
      {userIsLogged ? (
        props.children
      ) : (
        <SideBannerLayout>
          <NewLogin />
        </SideBannerLayout>
      )}
    </Route>
  );
};

export default RouteRequiresLogin;
