import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import useStyles from "../ManageTransactionAndInvoicesOverview/ManageTransactionAndInvoicesOverview.styles";
import { fget } from "../../../../../API/callsApi";
import CommonLoader from "../../../../ReusableComponents/CommonLoader/CommonLoader";
import TransactionFailedTableItem from "./TransactionFailedTableItem";
import useAuth from "../../../../../hooks/useAuth";
import CustomPagination from "../../../../ReusableComponents/CustomPagination/CustomPagination";
import NoTransactionFound from "../TransactionModals/NoTransactionFound";

const TransactionFailedTable = () => {
  const classes = useStyles();
  const { currentOrg } = useAuth();
  const [failedTransactions, setFailedTransaction] = useState({
    count: 0,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const getFailedTransaction = async () => {
    setIsLoading(true);
    try {
      const res = await fget({
        url: `/transactions/?payment_status=failed&org=${currentOrg.id}&page=${currentPage}`,
      });
      if (res.status === 200 || res.status === 201) {
        setFailedTransaction({
          count: res.data.count,
          data: res.data.results,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFailedTransaction();
  }, [currentOrg.id]);

  return (
    <>
      {isLoading ? (
        <div className={classes.commonLoader}>
          <CommonLoader />
        </div>
      ) : failedTransactions && failedTransactions.data.length > 0 ? (
        <>
          <TableContainer style={{ height: "7%", overflow: "hidden" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableRowBorder}`}
                  >
                    <span style={{ marginLeft: "10px" }}>Transaction ID</span>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableRowBorder}`}
                  >
                    Transaction Date
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableRowBorder}`}
                  >
                    License/ Invoice
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableRowBorder}`}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableRowBorder}`}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${classes.tableRowHeadService} ${classes.tableRowBorder}`}
                  >
                    <span style={{ marginRight: "40px" }}>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box
            style={{
              height: failedTransactions.count > 50 ? "81%" : "88%",
              overflowY: "auto",
              border: "1px solid #666666",
              borderRadius: "5px",
            }}
          >
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody style={{ height: "100%" }}>
                  {failedTransactions.data.map((eachTransaction) => {
                    return (
                      <TransactionFailedTableItem
                        key={eachTransaction.id}
                        eachTransaction={eachTransaction}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {failedTransactions.count > 50 && (
            <footer style={{ width: "100%", position: "relative" }}>
              <CustomPagination
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalItem={failedTransactions?.count}
                totalPage={
                  failedTransactions
                    ? Math.ceil(failedTransactions?.count / 50)
                    : 0
                }
                type="Available Licenses"
              />
            </footer>
          )}
        </>
      ) : (
        <NoTransactionFound text="Hmm, no transactions here yet .." />
      )}
    </>
  );
};

export default TransactionFailedTable;
