import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  Modal,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { CaretLeft, Minus, Plus } from "phosphor-react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { fget, fpost } from "../../API/callsApi";
import MovieColabIcon from "../ReusableComponents/Svgs/MovieColabIcon";
import PaymentConfirmedModal from "./PaymentPageModals/PaymentConfirmedModal";
import PaymentErrorModal from "./PaymentPageModals/PaymentErrorModal";
import { FilledButton } from "../ReusableComponents/Buttons/Buttons";
import CommonLoader from "../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../ReusableComponents/NoDataFound/NoDataFound";
import CheckCircleSvg from "../ReusableComponents/Svgs/CheckCircleSvg";

const PaymentOverview = () => {
  const { currentOrg } = useAuth();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search.substring(1));
  const [paymentConfirmModal, setPaymentConfirmModal] = useState(false);
  const [paymentErrorModal, setPaymentErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPricePlan, setSelectedPricePlan] = useState("monthly");
  const [transactionData, setTransactionData] = useState([]);
  const [isLoadingSessionData, setLoadingSessionData] = useState(false);
  const [orgServices, serOrgServices] = useState([]);
  const [monthlyPricePlan, setMonthlyPricePlan] = useState({
    type: "monthly",
    priceId: "",
    amount: "",
    totalNumber: 0,
  });
  const [yearlyPricePlan, setYearlyPricePlan] = useState({
    type: "yearly",
    priceId: "",
    amount: "",
    totalNumber: 0,
  });

  const handlePaymentConfirmedModal = () => {
    setPaymentConfirmModal(!paymentConfirmModal);
  };

  const handlePaymentErrorModal = () => {
    setPaymentErrorModal(!paymentErrorModal);
  };

  useEffect(() => {
    if (
      searchParams.get("paymentstatus") &&
      searchParams.get("paymentstatus") === "successful"
    ) {
      setPaymentConfirmModal(true);
    } else if (
      searchParams.get("paymentstatus") &&
      searchParams.get("paymentstatus") === "failed"
    ) {
      setPaymentErrorModal(true);
    }
    if (searchParams.get("session_id") && searchParams.get("paymentstatus")) {
      getTransaction(
        searchParams.get("paymentstatus"),
        searchParams.get("session_id")
      );
    }
  }, [currentOrg.id]);

  const getTransaction = async (status, id) => {
    setLoadingSessionData(true);
    try {
      const res = await fget({
        url: `/transactions/?payment_status=${status}&org=${currentOrg.id}&session_id=${id}`,
      });
      if (res.status === 200 || res.status === 201) {
        setTransactionData(res.data.results);
        setLoadingSessionData(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingSessionData(false);
    }
  };

  const getStripeProducts = async () => {
    setIsLoading(true);
    try {
      const res = await fget({
        url: "/stripe/product/",
      });
      if (res.status === 200 || res.status === 201) {
        const filtered = res.data.data.filter(
          (eachProduct) => eachProduct.metadata.service === "MovieColab"
        );
        if (filtered.length > 0 && filtered[0]?.prices?.data.length > 0) {
          filtered[0]?.prices?.data.forEach((pricePlan) => {
            if (pricePlan.lookup_key === "yearly_license_subscription") {
              setYearlyPricePlan({
                type: "yearly",
                priceId: pricePlan.id,
                amount: pricePlan.unit_amount,
                totalNumber: 0,
              });
            } else if (
              pricePlan.lookup_key === "monthly_license_subscription"
            ) {
              setMonthlyPricePlan({
                type: "monthly",
                priceId: pricePlan.id,
                amount: pricePlan.unit_amount,
                totalNumber: 0,
              });
            }
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getOrgServices = async () => {
    const url = `/admin/service/?org=${currentOrg.id}`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        const filteredData = res.data.filter(
          (eachService) => eachService.name === "MovieColab"
        );
        serOrgServices(filteredData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStripeProducts();
    getOrgServices();
  }, []);

  const buyLicense = async () => {
    const obj = {
      org: currentOrg.id,
      price_id:
        selectedPricePlan === "monthly"
          ? monthlyPricePlan.priceId
          : yearlyPricePlan.priceId,
      quantity:
        selectedPricePlan === "monthly"
          ? monthlyPricePlan.totalNumber
          : yearlyPricePlan.totalNumber,
      service: Number(params.sid),
      success_url: `${window.location.href}/?paymentstatus=successful`,
      failure_url: `${window.location.href}/?paymentstatus=failed`,
    };
    try {
      const res = await fpost({
        url: "/request-licenses/",
        data: obj,
      });
      window.location.href = res.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      boxSizing="border-box"
      px={4}
      py={3}
      style={{ height: "100%", overflow: "auto" }}
    >
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CommonLoader />
        </div>
      ) : monthlyPricePlan.priceId || yearlyPricePlan.priceId ? (
        <Grid container spacing={1} pr={6}>
          <Grid item xs={7} lg={8}>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Button
                sx={{
                  minWidth: "0px",
                  backgroundColor: "#000000",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  padding: "10px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#262626",
                  },
                }}
                onClick={() =>
                  history.push(
                    `/organizations/${currentOrg.id}/?orgInfoType=manage-services`
                  )
                }
              >
                <CaretLeft />
              </Button>
              <Typography variant="h5">Buy & Manage Services</Typography>
            </div>
            <Box
              mt={3}
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <Typography variant="h2" fontWeight={600}>
                Choose a Subscription Plan
              </Typography>
              <FormControl>
                <RadioGroup
                  value={selectedPricePlan}
                  onChange={(event) => setSelectedPricePlan(event.target.value)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "24px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        value="yearly"
                        disabled={
                          orgServices[0] &&
                          orgServices[0]?.subscription_exists
                            ?.yearly_subscription !== null
                        }
                        control={<Radio />}
                        label="Yearly License"
                      />
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        value="monthly"
                        control={<Radio />}
                        disabled={
                          orgServices[0] &&
                          orgServices[0]?.subscription_exists
                            ?.monthly_subscription !== null
                        }
                        label="Monthly License"
                      />
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  borderRadius: "10px",
                  border: "1px solid #000000",
                  width: "500px",
                }}
              >
                <Box
                  p={3}
                  sx={{
                    width: "50%",
                    background:
                      "radial-gradient(200% 100% at 50% 100%,  #FFFFFF 0%,  #BFFFB7 15%,  #63B4FF 40%, #564ECA 60%, #232058 75%, #101013 100%)",
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight={600}
                    textAlign="center"
                    color="#FFFFFF"
                  >
                    {selectedPricePlan === "yearly" &&
                      `Rs. ${yearlyPricePlan.amount}/yr`}
                    {selectedPricePlan === "monthly" &&
                      `Rs. ${monthlyPricePlan.amount}/mo`}
                  </Typography>
                  <Box
                    sx={{
                      padding: "12px 24px",
                      borderRadius: "5px",
                      backgroundColor: "#FFFFFF",
                      backdropFilter: "blur(4px)",
                      marginTop: "12px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={500}
                      textAlign="center"
                    >
                      {selectedPricePlan === "monthly" && "Monthly License"}
                      {selectedPricePlan === "yearly" && "Yearly License"}
                    </Typography>
                  </Box>
                </Box>
                <Box p={3} sx={{ width: "50%", border: "1px solid green" }}>
                  <Typography fontWeight={500} variant="h5" textAlign="center">
                    Choose the number of new{" "}
                    {selectedPricePlan === "yearly"
                      ? "yearly licenses"
                      : "monthly licenses"}
                    <span style={{ color: "#E01717" }}>*</span>
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "28px",
                      marginTop: "12px",
                    }}
                  >
                    <div style={{ width: "32px" }}>
                      <FilledButton
                        disabled={
                          (yearlyPricePlan.totalNumber < 1 &&
                            selectedPricePlan === "yearly") ||
                          (monthlyPricePlan.totalNumber < 1 &&
                            selectedPricePlan === "monthly")
                        }
                        small={true}
                        text={<Minus />}
                        submit={() => {
                          if (selectedPricePlan === "yearly") {
                            setYearlyPricePlan({
                              ...yearlyPricePlan,
                              totalNumber: yearlyPricePlan.totalNumber - 1,
                            });
                          } else if (selectedPricePlan === "monthly") {
                            setMonthlyPricePlan({
                              ...monthlyPricePlan,
                              totalNumber: monthlyPricePlan.totalNumber - 1,
                            });
                          }
                        }}
                      />
                    </div>
                    <Typography variant="h4">
                      {selectedPricePlan === "monthly" &&
                        monthlyPricePlan.totalNumber}
                      {selectedPricePlan === "yearly" &&
                        yearlyPricePlan.totalNumber}
                    </Typography>
                    <div style={{ width: "32px" }}>
                      <FilledButton
                        small={true}
                        text={<Plus />}
                        submit={() => {
                          if (selectedPricePlan === "yearly") {
                            setYearlyPricePlan({
                              ...yearlyPricePlan,
                              totalNumber: yearlyPricePlan.totalNumber + 1,
                            });
                          } else if (selectedPricePlan === "monthly") {
                            setMonthlyPricePlan({
                              ...monthlyPricePlan,
                              totalNumber: monthlyPricePlan.totalNumber + 1,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </Box>
              </div>
              <Box
                mt={3}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <div style={{ display: "flex", gap: "6px" }}>
                  <CheckCircleSvg small={true} />
                  <Typography variant="h5">
                    1 Movie Colab Account per license (valid for{" "}
                    <span style={{ fontWeight: "500" }}>
                      {selectedPricePlan === "monthly" ? "1 month" : "1 year"}
                    </span>
                    )
                  </Typography>
                </div>
                <Typography
                  sx={{ marginLeft: "26px" }}
                  variant="h6"
                  color="#666666"
                >
                  Get a dedicated Movie Colab account that provides full access
                  to all collaboration tools, personalized settings, and project
                  management features tailored to your workflow.
                </Typography>

                <div
                  style={{ display: "flex", gap: "6px", margin: "16px 0px" }}
                >
                  <CheckCircleSvg small={true} />
                  <Typography variant="h5">
                    Movie Colab Web & Desktop
                  </Typography>
                </div>
                <div style={{ display: "flex", gap: "6px" }}>
                  <CheckCircleSvg small={true} />
                  <Typography variant="h5">24/7 Customer Support</Typography>
                </div>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} lg={4} sx={{ mt: { xs: 3, lg: 0 } }}>
            <Box
              p={4}
              style={{ border: "1px solid #BBBBBB", borderRadius: "20px" }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <MovieColabIcon />
                <Typography variant="h1" style={{ fontWeight: "500" }}>
                  Movie Colab
                </Typography>
              </div>
              <Grid container mt={4} mb={2}>
                <Grid item xs={7}>
                  <Typography variant="h5" color="#666666">
                    Organization
                  </Typography>
                </Grid>
                <Grid item xs={1} textAlign="center">
                  :
                </Grid>
                <Grid item xs={4}>
                  <Typography fontWeight={600} variant="h5" textAlign="right">
                    {currentOrg.name}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              {selectedPricePlan === "monthly" && (
                <Grid container mt={2} mb={2}>
                  <Grid item xs={7}>
                    <Typography variant="h5" color="#666666">
                      Monthly Subscription
                    </Typography>
                  </Grid>
                  <Grid item xs={1} textAlign="center">
                    :
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" textAlign="right">
                      {monthlyPricePlan.totalNumber} x {monthlyPricePlan.amount}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {selectedPricePlan === "yearly" && (
                <Grid container mt={2} mb={2}>
                  <Grid item xs={7}>
                    <Typography variant="h5" color="#666666">
                      Yearly Subscription
                    </Typography>
                  </Grid>
                  <Grid item xs={1} textAlign="center" mt>
                    :
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" textAlign="right">
                      {yearlyPricePlan.totalNumber} x {yearlyPricePlan.amount}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Divider />
              <Grid container mt={2}>
                <Grid item xs={7}>
                  <Typography variant="h5" color="#666666">
                    Purchase Amount
                  </Typography>
                </Grid>
                <Grid item xs={1} textAlign="center" mt>
                  :
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h3"
                    textAlign="right"
                    style={{ fontWeight: "600" }}
                  >
                    {selectedPricePlan === "monthly" &&
                      monthlyPricePlan.amount * monthlyPricePlan.totalNumber}
                    {selectedPricePlan === "yearly" &&
                      yearlyPricePlan.amount * yearlyPricePlan.totalNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Box
                mt={2}
                mb={2}
                style={{
                  borderRadius: "5px",
                  border: "1px solid #635BFF",
                  padding: "10px",
                  color: "#635BFF",
                  width: "max-content",
                }}
              >
                <Typography variant="h5">
                  Powered by <span style={{ fontWeight: "600" }}>stripe</span>
                </Typography>
              </Box>
              <div style={{ width: "200px" }}>
                <FilledButton
                  text="Pay Now"
                  disabled={
                    monthlyPricePlan.totalNumber === 0 &&
                    yearlyPricePlan.totalNumber === 0
                  }
                  submit={buyLicense}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <NoDataFound />
      )}
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={paymentConfirmModal}
        onClose={handlePaymentConfirmedModal}
      >
        <PaymentConfirmedModal
          closeModal={handlePaymentConfirmedModal}
          transactionData={transactionData[0]}
          isLoadingSessionData={isLoadingSessionData}
          currentOrg={currentOrg.id}
          serviceId={params.sid}
          searchParams={searchParams}
        />
      </Modal>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={paymentErrorModal}
        onClose={handlePaymentErrorModal}
      >
        <PaymentErrorModal
          closeModal={handlePaymentErrorModal}
          searchParams={searchParams}
        />
      </Modal>
    </Box>
  );
};

export default PaymentOverview;
