import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import { LockKeyOpen } from "phosphor-react";
import { FilledButton } from "../Buttons/Buttons";
import { fget } from "../../../API/callsApi";
import { useHistory } from "react-router-dom";

const ResetPasswordModal = () => {
  const history = useHistory();
  const [openResetPasswordModal, setResetPasswordModal] = useState(false);
  const [userData, setUserData] = useState({});

  const loadUserData = async () => {
    let userData = {
      url: "/user/",
    };

    try {
      const res = await fget(userData);
      if (res?.status === 200) {
        setUserData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const hasModalBeenShown = sessionStorage.getItem("modalShown");

    if (!hasModalBeenShown) {
      const timer = setTimeout(() => {
        setResetPasswordModal(!userData.default_password_changed);
        sessionStorage.setItem("modalShown", "true");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [userData.default_password_changed]);

  useEffect(() => {
    loadUserData();
  }, []);

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={openResetPasswordModal}
      onClose={() => setResetPasswordModal(false)}
    >
      <Box
        sx={{
          width: "500px",
          borderRadius: "10px",
          p: 4,
          bgcolor: "#FFFFFF",
          border: "1px solid white",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <LockKeyOpen size={26} />
          <Typography variant="h2" fontWeight={600}>
            Reset Password
          </Typography>
        </div>
        <Typography variant="h5" textAlign="center" mt={4} mb={4}>
          You currently have a credential password set. For security, it’s
          recommended to reset it to a custom password.
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            sx={{
              bgcolor: "#FFFFFF",
              color: "#000000",
              fontWeight: "500",
              textDecoration: "underline",
              textTransform: "none",
            }}
            onClick={() => setResetPasswordModal(false)}
          >
            Remind me later
          </Button>
          <div style={{ width: "200px" }}>
            <FilledButton
              submit={() => {
                history.push("/forgotpassword");
                setResetPasswordModal(false);
              }}
              text="Reset Password"
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ResetPasswordModal;
