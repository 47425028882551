import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Box, Typography } from "@mui/material";
import { Headset } from "phosphor-react";
import useStyles from "../CreateOrganizationOverview/CreateOrganization.styles";
import CheckCircleSvg from "../../ReusableComponents/Svgs/CheckCircleSvg";

const CreateOrgRequestPending = () => {
  const classes = useStyles();
  return (
    <Box
      px={16}
      py={8}
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <Box
        gap={4}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className={classes.gapContainer2}>
          <CheckCircleSvg />
          <Typography fontWeight={600} variant="h1">
            Your request for an organization has been sent!
          </Typography>
        </div>
        <Typography ml={6} variant="h5">
          We’re currently processing your request and will get back to you
          shortly.
        </Typography>
        <Typography ml={6} color="#666666" variant="h4">
          In the meantime, please do check out our{" "}
          <a
            href="https://vigaet.com/blog"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={classes.blackText}>blog</span>
          </a>{" "}
          posts for more info about our
          <span className={classes.blackText}> products</span> and{" "}
          <span className={classes.blackText}>services</span>.
        </Typography>
      </Box>
      <div className={classes.footer}>
        <Headset /> Need help? Contact{" "}
        <Link to="#">
          <span style={{ color: "#666666" }}>support@vigastudios.com</span>
        </Link>
      </div>
    </Box>
  );
};

export default CreateOrgRequestPending;
