import React from "react";
import { Box, Typography } from "@mui/material";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import { CaretLeft } from "phosphor-react";
import { useHistory } from "react-router-dom";
import { deleteCookie } from "../../../authorization";

const SetupServiceOrgRequired = () => {
  const history = useHistory();

  const logOut = () => {
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");
    deleteCookie("refresh");
    deleteCookie("access");
  };

  return (
    <Box boxSizing="border-box" px={8} py={3} style={{ height: "100%" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div>
          <FilledButton
            text={<CaretLeft size={20} />}
            submit={() => history.push("/services")}
          />
        </div>
        <Typography variant="h5">Explore Services</Typography>
      </div>
      <Box mt={4}>
        <Typography variant="h1" fontWeight={600}>
          Organization Required
        </Typography>
        <Typography mt={2} variant="h4">
          We&apos;re excited that you&apos;re interested in our products! To
          ensure seamless collaboration and access, some of our services, like
          Movie Colab, require an organization or organization owner account to
          setup. This helps your team manage and use our tools efficiently.
        </Typography>
        <Box
          mt={3}
          mb={3}
          style={{ display: "flex", alignItems: "center", gap: "24px" }}
        >
          <div style={{ width: "340px" }}>
            <FilledButton
              submit={() => {
                logOut();
                history.push("/logout/2");
              }}
              text="Login to your Org Owner account"
            />
          </div>
          <Typography variant="h4">or</Typography>
          <div style={{ width: "240px" }}>
            <OutlinedButton
              text="Create Organization"
              submit={() => {
                history.push("/create-org");
              }}
            />
          </div>
        </Box>
        <Typography variant="h1" fontWeight={600}>
          Got questions or need assistance?
        </Typography>
        <Typography mt={2} variant="h5">
          Our support team is here 24/7. Click here to contact support or email
          us your queries at cloud@vigaet.com
        </Typography>
      </Box>
    </Box>
  );
};

export default SetupServiceOrgRequired;
