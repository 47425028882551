import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Typography,
} from "@mui/material";
import useStyles from "../../NewOrganizationsOverview/NewOrganizationsOverview.styles";
import InvitationPlus from "../../../../ReusableComponents/Svgs/InvitationPlus";
import { fget, fpost } from "../../../../../API/callsApi";
import CommonLoader from "../../../../ReusableComponents/CommonLoader/CommonLoader";
import CustomPagination from "../../../../ReusableComponents/CustomPagination/CustomPagination";
import CharacterWithDialogue from "../../../../ReusableComponents/Svgs/CharacterWithDialogue";
import { toast } from "react-hot-toast";

const FailedInvitationsTable = ({ currentOrganization, userSearchValue }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [allFailedInvitations, setAllFailedInvitations] = useState({
    count: 0,
    data: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const getFailedInvitationUser = async () => {
    setIsLoading(true);
    const url = `/organization/${currentOrganization}/sent-invites/?status=${"Rejected"}&page=${currentPage}`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setAllFailedInvitations({
          count: res.data.count,
          data: res.data.results,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const reInviteUser = async (userEmail) => {
    let formData = {
      email: userEmail,
    };
    try {
      let obj = {
        url: `/organization/${currentOrganization}/invite-user/`,
        data: formData,
      };
      const res = await fpost(obj);
      if (res.status === 200 || res.status === 201) {
        toast.success("Invitation sent to user");
        handlePageChange(1);
      }
    } catch (error) {
      toast.error("Error on Sending Invitation");
    }
  };

  useEffect(() => {
    getFailedInvitationUser();
  }, [currentOrganization, updateOnPageChange]);

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <CommonLoader />
        </div>
      ) : allFailedInvitations && allFailedInvitations.data.length > 0 ? (
        <Box sx={{ height: "100%", width: "100%" }}>
          <TableContainer style={{ height: "6%", overflow: "hidden" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                  >
                    User
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                  >
                    Email ID
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                  >
                    Sent on
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                    sx={{ width: "27% !important" }}
                  >
                    <span style={{ color: "#E01717" }}>Cancelled on</span>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                    sx={{ width: "13% !important" }}
                  >
                    <span style={{ marginRight: "40px" }}> Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box
            style={{
              height: allFailedInvitations.count > 50 ? "80%" : "87%",
              overflowY: "auto",
              border: "1px solid #666666",
              borderRadius: "5px",
            }}
          >
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody style={{ height: "100%" }}>
                  {allFailedInvitations.data
                    ?.filter((search) => {
                      if (userSearchValue === undefined) {
                        return search;
                      } else if (userSearchValue === "") {
                        return search;
                      } else if (
                        search?.user?.first_name
                          ?.toLowerCase()
                          ?.includes(userSearchValue.toLowerCase())
                      ) {
                        return search;
                      } else if (
                        search.user?.last_name
                          ?.toLowerCase()
                          .includes(userSearchValue.toLowerCase())
                      ) {
                        return search;
                      }
                      return null;
                    })
                    .map((eachUser) => (
                      <TableRow
                        key={eachUser.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                        >
                          <Typography variant="h5" fontWeight={500}>
                            {eachUser.user?.first_name
                              ? `${eachUser.user?.first_name} ${eachUser.user?.last_name}`
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                        >
                          <Typography variant="h5">
                            {eachUser.user_email ? eachUser.user_email : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                        >
                          <Typography variant="h5">
                            {new Date(eachUser.sent_date).toLocaleDateString(
                              "en-US",
                              {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                          sx={{ width: "27% !important" }}
                        >
                          <Typography variant="h5" color="#E01717">
                            {new Date(eachUser.updated_date).toLocaleDateString(
                              "en-US",
                              {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                          sx={{ width: "13% !important" }}
                        >
                          <Button
                            style={{
                              margin: "0px",
                              padding: "5px 10px",
                              minWidth: "0px",
                              textTransform: "none",
                              marginRight: "40px",
                            }}
                            disabled={eachUser.user_email === null}
                            onClick={() => reInviteUser(eachUser.user_email)}
                          >
                            <InvitationPlus color="#000000" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {allFailedInvitations.count > 50 && (
            <footer style={{ width: "100%", position: "relative" }}>
              <CustomPagination
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalItem={allFailedInvitations?.count}
                totalPage={
                  allFailedInvitations
                    ? Math.ceil(allFailedInvitations?.count / 50)
                    : 0
                }
                type="Invitations Users"
              />
            </footer>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          mt={15}
        >
          <Box
            style={{
              position: "relative",
            }}
          >
            <CharacterWithDialogue />
            <Box sx={{ marginLeft: "220px", marginTop: "-150px" }}>
              <Typography variant="h5" textAlign="left">
                The invitation list is empty!
              </Typography>
              <Typography variant="h5" textAlign="left" mt={2}>
                Time to invite some users
                <br /> to your party!
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FailedInvitationsTable;
