import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import { Report } from "@mui/icons-material";
import {
  OutlinedButton,
  RedFilledButton,
} from "../../../../ReusableComponents/Buttons/Buttons";

const ReportTransactionModal = ({ closeModal }) => {
  return (
    <Box
      sx={{
        width: "600px",
        padding: "36px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px",
        backgroundColor: "white",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <Report style={{ color: "#E01717", height: "28px", width: "28px" }} />
        <Typography fontWeight={600} variant="h2">
          Report Transaction
        </Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
        <Typography variant="h5">Transaction ID</Typography>
        <Typography variant="h5">:</Typography>
        <Typography variant="h5">1234566789</Typography>
      </div>
      <TextField label="Reason" variant="outlined" fullWidth />
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <div style={{ width: "200px" }}>
          <OutlinedButton text="Cancel" submit={closeModal} />
        </div>
        <div style={{ width: "200px" }}>
          <RedFilledButton
            text="Report Transaction"
            submit={() => console.log("submit")}
          />
        </div>
      </div>
    </Box>
  );
};

export default ReportTransactionModal;
