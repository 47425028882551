import React from "react";
import { Box, Typography } from "@mui/material";
import NotFoundCharacter from "../../../../ReusableComponents/Svgs/NotFoundCharacter";

const NoTransactionFound = ({ text }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        mt: 10,
      }}
    >
      <Box>
        <NotFoundCharacter />
        <Typography variant="h5" textAlign="center" mt={1}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default NoTransactionFound;
