import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Modal,
  Chip,
} from "@mui/material";
import { Info } from "phosphor-react";
import useStyles from "../LicensesOverview/LicensesOverview.styles";
import LicenseInfoModal from "../LicensePageModals/LicenseInfoModal";

const CancelledLicenseTableItem = ({ eachLicense }) => {
  const classes = useStyles();
  const [isLicenseInfoModal, setLicenseInfoModal] = useState(false);

  const handleLicenseInfoModal = () => {
    setLicenseInfoModal(!isLicenseInfoModal);
  };

  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell
          component="th"
          scope="row"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
        >
          <Typography variant="h5" fontWeight={600}>
            {eachLicense.license_number}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
        >
          <Chip label={eachLicense.license_type} variant="outlined" />
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
        >
          <Typography variant="h5">
            {eachLicense.user?.first_name} {eachLicense.user?.last_name}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
        >
          <Typography variant="h5">
            {eachLicense.subscribed_on
              ? new Date(eachLicense.subscribed_on).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )
              : "--"}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
        >
          <Typography variant="h5" color="#E01717">
            {new Date(eachLicense.license_validity).toLocaleDateString(
              "en-US",
              {
                month: "long",
                day: "numeric",
                year: "numeric",
              }
            )}
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
        >
          <Button
            style={{
              margin: "0px",
              padding: "5px",
              minWidth: "0px",
              textTransform: "none",
              marginRight: "45px",
            }}
            onClick={handleLicenseInfoModal}
          >
            <Info size={20} color="#000000" />
          </Button>
        </TableCell>
      </TableRow>
      <Modal
        open={isLicenseInfoModal}
        className={classes.centerContainer}
        onClose={handleLicenseInfoModal}
      >
        <LicenseInfoModal
          closeModal={handleLicenseInfoModal}
          licenseInfo={eachLicense}
        />
      </Modal>
    </>
  );
};

export default CancelledLicenseTableItem;
