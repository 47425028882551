import React, { useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  Modal,
  Box,
} from "@mui/material";
import { MagnifyingGlass, UserPlus } from "phosphor-react";
import ActiveUsersTable from "../ActiveUsersTable/ActiveUsersTable";
import InvitationsTable from "../InvitationsTable/InvitaionsTable";
import FailedInvitationsTable from "../FailedInvitationsTable/FailedInvitationsTable";
import { FilledButton } from "../../../../ReusableComponents/Buttons/Buttons";
import AddNewUserModal from "../../../OrganizationsModals/AddNewUserModal/AddNewUserModal";

const ManageUsersOverview = ({ selectedOrg, userType }) => {
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const [userSearchValue, setUserSearchValue] = useState("");
  const [userRoleValue, setUserRoleValue] = useState("All");
  const [refreshCounter, setRefreshCounter] = useState(0);

  const handleAddNewUserModal = () => {
    setAddNewUserModal(!addNewUserModal);
  };

  const handleRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  return (
    <>
      <Box
        mb={2}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <TextField
            size="small"
            label="search"
            variant="outlined"
            value={userSearchValue}
            onChange={(event) => setUserSearchValue(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MagnifyingGlass size={22} />
                </InputAdornment>
              ),
            }}
          />

          {userType === "active" && (
            <FormControl size="small" style={{ width: "200px" }}>
              <InputLabel
                style={{ padding: "0px 5px" }}
                id="demo-simple-select-helper-label-org"
              >
                User Role
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                variant="outlined"
                label="Organization"
                value={userRoleValue}
                onChange={(event) => setUserRoleValue(event.target.value)}
                style={{ width: "100%" }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="User">User</MenuItem>
                <MenuItem value="Owner">Owner</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>
        <div style={{ width: "190px" }}>
          <FilledButton
            disabled={false}
            text="Add New User"
            submit={handleAddNewUserModal}
            icon={<UserPlus />}
          />
        </div>
      </Box>
      {userType === "active" && (
        <ActiveUsersTable
          currentOrganization={selectedOrg.id}
          selectedOrg={selectedOrg}
          userSearchValue={userSearchValue}
          userRoleValue={userRoleValue}
        />
      )}
      {userType === "failedInvite" && (
        <FailedInvitationsTable
          currentOrganization={selectedOrg.id}
          userSearchValue={userSearchValue}
        />
      )}
      {userType === "invited" && (
        <InvitationsTable
          currentOrganization={selectedOrg.id}
          userSearchValue={userSearchValue}
          refreshCounter={refreshCounter}
        />
      )}
      <Modal
        open={addNewUserModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={handleAddNewUserModal}
      >
        <AddNewUserModal
          closeModal={handleAddNewUserModal}
          handleRefreshCounter={handleRefreshCounter}
        />
      </Modal>
    </>
  );
};

export default ManageUsersOverview;
