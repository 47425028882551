import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Box,
  Button,
  Typography,
  Modal,
  Grid,
} from "@mui/material";
import { Info, PencilSimple, Trash, User } from "phosphor-react";
import toast from "react-hot-toast";
import { fpatch } from "../../../../../API/callsApi";
import AdminUserIcon from "../../../../ReusableComponents/Svgs/AdminUserIcon"; //
import useStyles from "../../NewOrganizationsOverview/NewOrganizationsOverview.styles";
import DeleteModal from "../../../../ReusableComponents/DeleteModal/DeleteModal";
import UserInfoModal from "../../../OrganizationsModals/NewUserInfoModal/UserInfoModal";
import ChangeUserRoleModal from "../../../OrganizationsModals/ChangeUserRoleModal/ChangeUserRoleModal";
import useAuth from "../../../../../hooks/useAuth";
import DefaultImg from "../../../../../assets/defaultimg.png";

const ActiveUserTableItems = ({ eachUser, handleRefreshCounter }) => {
  const classes = useStyles();
  const { currentOrg } = useAuth();
  const [isUserInfoModal, setUserInfoModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [adminModalOpen, setAdminModalOpen] = useState(false);
  const [isRemovingUser, setIsRemovingUser] = useState(false);

  const handleUserInfoModal = () => {
    setUserInfoModal(!isUserInfoModal);
  };

  const handleRemoveUserModal = () => {
    setRemoveUserModal(!removeUserModal);
  };

  const handleAdminModalOpen = () => {
    setAdminModalOpen(!adminModalOpen);
  };

  const removeConnectionAndUser = async () => {
    setIsRemovingUser(true);
    const url = `/organization/${currentOrg.id}/user/${eachUser.id}/remove/`;
    try {
      const res = await fpatch({ url });
      if (res.status === 200 || res.status === 201) {
        handleRefreshCounter();
        handleRemoveUserModal();
        toast.success("User Successfully removed");
        setIsRemovingUser(false);
      }
    } catch (error) {
      toast.error("Some error occured");
      setIsRemovingUser(false);
    }
  };

  return (
    <TableRow
      key={eachUser.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell
        component="th"
        scope="row"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
        sx={{ width: "18% !important" }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <img
            height={36}
            width={36}
            style={{ borderRadius: "2px" }}
            src={eachUser.avatar ? eachUser.avatar : DefaultImg}
          />
          <Typography variant="h5" fontWeight={500}>
            {eachUser.first_name} {eachUser.last_name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
        sx={{ width: "18% !important" }}
      >
        <Typography
          variant="h5"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          {eachUser.role === "Admin" ? (
            <AdminUserIcon size="small" />
          ) : (
            <User size={22} />
          )}{" "}
          {eachUser.role}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
        sx={{ width: "28% !important" }}
      >
        <Typography variant="h5">{eachUser.email}</Typography>
      </TableCell>
      <TableCell
        align="center"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
        sx={{ width: "20% !important" }}
      >
        <Typography variant="h5">
          {eachUser.added_on
            ? new Date(eachUser.added_on).toLocaleDateString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              })
            : "--"}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
        sx={{ width: "16% !important" }}
      >
        <Button
          style={{
            margin: "0px",
            padding: "5px",
            minWidth: "0px",
            textTransform: "none",
          }}
          onClick={handleUserInfoModal}
        >
          <Info size={20} color="#000000" />
        </Button>
        <Button
          style={{
            margin: "0px",
            padding: "5px",
            minWidth: "0px",
            textTransform: "none",
          }}
          onClick={handleAdminModalOpen}
        >
          <PencilSimple size={20} color="#000000" />
        </Button>
        <Button
          style={{
            margin: "0px",
            padding: "5px",
            minWidth: "0px",
            textTransform: "none",
            marginRight: "10px",
          }}
          onClick={handleRemoveUserModal}
        >
          <Trash size={20} color="#E01717" />
        </Button>
      </TableCell>
      <Modal
        open={isUserInfoModal}
        className={classes.centerContainer}
        onClose={handleUserInfoModal}
      >
        <UserInfoModal closeModal={handleUserInfoModal} userInfo={eachUser} />
      </Modal>
      <Modal
        open={removeUserModal}
        className={classes.centerContainer}
        onClose={handleRemoveUserModal}
      >
        <DeleteModal
          isRemoving={isRemovingUser}
          handleDelete={removeConnectionAndUser}
          leave={false}
          text1="Are you sure you want to delete the following User?"
          text2={
            <Box>
              <Grid container>
                <Grid item xs={5} color="#666666">
                  <Typography variant="h5" color="#666666">
                    User
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h5">:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h5">
                    {eachUser.first_name} {eachUser.last_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={5} color="#666666">
                  <Typography variant="h5" color="#666666">
                    Email ID
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h5">:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h5">{eachUser.email}</Typography>
                </Grid>
              </Grid>
            </Box>
          }
          closeModal={handleRemoveUserModal}
          btnText="Delete User"
        />
      </Modal>
      <Modal
        open={adminModalOpen}
        className={classes.centerContainer}
        onClose={handleAdminModalOpen}
      >
        <ChangeUserRoleModal
          closeModal={handleAdminModalOpen}
          userInfo={eachUser}
          currentOrg={currentOrg}
          handleRefreshCounter={handleRefreshCounter}
        />
      </Modal>
    </TableRow>
  );
};

export default ActiveUserTableItems;
