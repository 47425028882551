import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Info, X } from "phosphor-react";

const LicenseInfoModal = ({ closeModal, licenseInfo, type }) => {
  return (
    <Box
      p={4}
      style={{
        borderRadius: "10px",
        width: "500px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Info size={25} />
          <Typography variant="h2" fontWeight={600}>
            License Info
          </Typography>
        </div>
        <Button onClick={closeModal}>
          <X color="#000000" size={25} />
        </Button>
      </div>
      <Box mt={3}>
        <Grid container>
          <Grid item xs={5} color="#666666">
            Service Name
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {licenseInfo?.service?.name}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            License ID
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {licenseInfo.license_number}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            Subscribed by
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {licenseInfo.subscribed_by?.first_name}{" "}
            {licenseInfo.subscribed_by?.last_name}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            Subscribed on
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {licenseInfo.subscribed_on
              ? new Date(licenseInfo.subscribed_on).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )
              : "--"}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            Expiring on
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {new Date(licenseInfo.license_validity).toLocaleDateString(
              "en-US",
              {
                month: "long",
                day: "numeric",
                year: "numeric",
              }
            )}
          </Grid>
        </Grid>
        {type !== "available" && (
          <Grid container mt={1}>
            <Grid item xs={5} color="#666666">
              User Assigned
            </Grid>
            <Grid item xs={2}>
              :
            </Grid>
            <Grid item xs={5}>
              {licenseInfo.user?.first_name} {licenseInfo.user?.last_name}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default LicenseInfoModal;
