import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import useStyles from "./ServiceOverview.styles";
import { fget } from "../../../../API/callsApi";
import { CalendarCheck, CaretLeft, CloudCheck, File } from "phosphor-react";
import { FilledButton } from "../../../ReusableComponents/Buttons/Buttons";
import ServiceSubscriptionPlan from "../ServiceSubscription/ServiceSubscriptionPlan";
import PluginIcon from "../../../ReusableComponents/Svgs/PluginIcon";
import MessageIcon from "../../../ReusableComponents/Svgs/MessageIcon";

const ServiceOverview = () => {
  const classes = useStyles();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState("overview");
  const [currentProduct, setCurrentProduct] = useState({});

  const getServiceProducts = async () => {
    try {
      const res = await fget({
        url: "/service-products/",
      });
      const filteredProduct = res.data.filter(
        (eachProduct) => eachProduct.name === "MovieColab"
      );
      setCurrentProduct(filteredProduct[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getServiceProducts();
  }, []);

  return (
    <Box
      boxSizing="border-box"
      px={4}
      style={{ height: "100%", marginTop: "13px" }}
    >
      <Grid container spacing={3} height="100%">
        <Grid item xs={3} lg={2} style={{ height: "100%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div>
              <FilledButton
                text={<CaretLeft size={20} />}
                submit={() => history.push("/services")}
              />
            </div>
            <Typography variant="h5">Explore Services</Typography>
          </div>
          <Box mt={3} className={classes.listContainer}>
            <List style={{ padding: "0px", height: "max-content" }}>
              <ListItem
                button={true}
                onClick={() => {
                  setCurrentTab("overview");
                }}
                className={
                  currentTab === "overview"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemText primary="Overview" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  setCurrentTab("Subscription");
                }}
                className={
                  currentTab === "Subscription"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemText primary="Subscription Plans" />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={9} lg={10} style={{ height: "100%" }}>
          {currentTab === "overview" && <OverviewTab />}
          {currentTab === "Subscription" && (
            <ServiceSubscriptionPlan currentServiceProduct={currentProduct} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const OverviewTab = () => {
  const classes = useStyles();
  return (
    <Box style={{ height: "100%" }}>
      <Typography variant="h5">
        Movie Colab is a cutting-edge platform offering cloud and AI-driven
        production workflow management tools tailored for the media and
        entertainment industry. Movie Colab is designed to empower filmmakers,
        studios, and creative teams of all sizes.
      </Typography>
      <Typography variant="h2" style={{ fontWeight: "600", marginTop: "12px" }}>
        Core Services
      </Typography>
      <ul
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <li>
          <Typography variant="h5">
            Cloud-Based Workflow Management:{" "}
            <span className={classes.tableRowCellsColor}>
              Seamlessly collaborate and manage projects from anywhere.
            </span>
          </Typography>
        </li>
        <li>
          <Typography variant="h5">
            AI-Enhanced Automation:{" "}
            <span className={classes.tableRowCellsColor}>
              Utilize AI to automate repetitive tasks, enhancing productivity
              and efficiency.
            </span>
          </Typography>
        </li>
        <li>
          <Typography variant="h5">
            B2C SaaS Solutions:{" "}
            <span className={classes.tableRowCellsColor}>
              Scalable software solutions suitable for both small teams and
              large enterprises.
            </span>
          </Typography>
        </li>
      </ul>
      <Typography variant="h2" style={{ fontWeight: "600", marginTop: "12px" }}>
        Features
      </Typography>
      <Typography
        color="#666"
        variant="h5"
        style={{ margin: "8px 0px 0px 0px" }}
      >
        Movie Colab offers a comprehensive suite of virtual production and
        management tools to streamline real-time movie creation.
      </Typography>
      <div style={{ display: "flex", alignItems: "flex-start", gap: "30px" }}>
        <List>
          <ListItem sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <File size={20} weight="fill" color="#000000" />
            <Typography variant="h6">Project & File Management</Typography>
          </ListItem>
          <ListItem sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <PluginIcon />
            <Typography variant="h6">Plugin Integration</Typography>
          </ListItem>
          <ListItem sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <MessageIcon />
            <Typography variant="h6">Real-time Work Reviews</Typography>
          </ListItem>
        </List>
        <List>
          <ListItem sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CalendarCheck size={20} />
            <Typography variant="h6">Task Management with Templates</Typography>
          </ListItem>
          <ListItem sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CloudCheck size={22} color="#000000" weight="fill" />
            <Typography variant="h6">
              Cloud Delivery and Synchronization
            </Typography>
          </ListItem>
        </List>
      </div>
      <Typography variant="h2" style={{ fontWeight: "600" }}>
        Industries We Serve
      </Typography>
      <Box mt={1} style={{ display: "flex", alignItem: "center", gap: "15px" }}>
        <Chip
          sx={{ color: "#666666" }}
          label="Film Production"
          variant="outlined"
        />
        <Chip
          sx={{ color: "#666666" }}
          label="Television Broadcasting"
          variant="outlined"
        />
        <Chip
          sx={{ color: "#666666" }}
          label="Digital Media Companies"
          variant="outlined"
        />
        <Chip
          sx={{ color: "#666666" }}
          label="Independent Creators"
          variant="outlined"
        />
      </Box>
    </Box>
  );
};

export default ServiceOverview;
