import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  InputAdornment,
  Modal,
} from "@mui/material";
import { CaretLeft, MagnifyingGlass, Plus } from "phosphor-react";
import useStyles from "./LicensesOverview.styles";
import ActiveLicensesTable from "../ActiveLicensesTable/ActiveLicensesTable";
import AvailableLicensesTable from "../AvailableLicensesTable/AvailableLicensesTable";
import CancelledLicensesTable from "../CancelledLicensesTable/CancelledLicensesTable";
import ExpiredLicensesTable from "../ExpiredLicensesTable/ExpiredLicensesTable";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import useAuth from "../../../hooks/useAuth";
import { fget } from "../../../API/callsApi";
import AddLicensesModal from "../LicensePageModals/AddLicensesModal";
import MovieColabIcon from "../../ReusableComponents/Svgs/MovieColabIcon";

const LicensesOverview = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { currentOrg } = useAuth();
  const [currentValue, setCurrentValue] = useState("active");
  const [licenseSearchValue, setLicenseSearchValue] = useState("");
  const [orgServices, serOrgServices] = useState([]);
  const [isAddLicenseModalOpen, setAddLicenseModalOpen] = useState(false);
  const searchParams = new URLSearchParams(
    history.location.search.substring(1)
  );

  const handleAddLicenseModal = () => {
    setAddLicenseModalOpen(!isAddLicenseModalOpen);
  };

  const getOrgServices = async () => {
    const url = `/admin/service/?org=${currentOrg.id}`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        const filteredData = res.data.filter(
          (eachService) => eachService.name === "MovieColab"
        );
        serOrgServices(filteredData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrgServices();
  }, []);

  useEffect(() => {
    searchParams.get("licensetype") &&
      setCurrentValue(searchParams.get("licensetype"));
  }, [searchParams.get("licensetype")]);

  return (
    <Box boxSizing="border-box" px={4} py={3} style={{ height: "100%" }}>
      <Grid container spacing={3} height="100%">
        <Grid item xs={3} lg={2} style={{ height: "100%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div>
              <FilledButton
                text={<CaretLeft size={20} />}
                submit={() =>
                  history.push(
                    `/organizations/${params.orgId}/?orgInfoType=manage-services`
                  )
                }
              />
            </div>
            <Typography variant="h5">Manage Services</Typography>
          </div>
          <Box mt={5} className={classes.listContainer}>
            <List sx={{ padding: "0px", height: "max-content" }}>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("licensetype", "active");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  currentValue === "active"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
                secondaryAction={
                  <ListItemText primary={orgServices[0]?.license_stats?.used} />
                }
              >
                <ListItemText primary="Active" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("licensetype", "available");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  currentValue === "available"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
                secondaryAction={
                  <ListItemText
                    primary={orgServices[0]?.license_stats?.available}
                  />
                }
              >
                <ListItemText primary="Available" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("licensetype", "expired");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  currentValue === "expired"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
                secondaryAction={
                  <ListItemText
                    primary={orgServices[0]?.license_stats?.expired}
                  />
                }
              >
                <ListItemText primary="Expired" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("licensetype", "cancelled");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  currentValue === "cancelled"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
                secondaryAction={
                  <ListItemText
                    primary={
                      orgServices[0]?.license_stats?.total -
                      (orgServices[0]?.license_stats?.available +
                        orgServices[0]?.license_stats?.expired +
                        orgServices[0]?.license_stats?.used)
                    }
                  />
                }
              >
                <ListItemText primary="Cancelled" />
              </ListItem>
            </List>
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              marginTop: "24px",
            }}
          >
            <TextField
              label="search"
              variant="outlined"
              value={licenseSearchValue}
              onChange={(event) => setLicenseSearchValue(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MagnifyingGlass size={22} />
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControl>
              <InputLabel
                style={{ padding: "0px 5px" }}
                id="demo-simple-select-helper-label-user"
              >
                Subscribed
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                variant="outlined"
                label="Organization"
                style={{ width: "100%" }}
              >
                <MenuItem value={1}>All</MenuItem>
                <MenuItem value={2}>Till now</MenuItem>
                <MenuItem value={3}>Till now</MenuItem>
              </Select>
            </FormControl> */}
            {/* <FormControlLabel control={<Switch checked={expiringSoon} onChange={(event) => setExpiringSoon(event.target.value)} />} label="Expiring Soon" /> */}
          </div>
        </Grid>
        <Grid item xs={9} lg={10} style={{ height: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontWeight={600}
              variant="h1"
              sx={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <MovieColabIcon />
              Movie Colab
            </Typography>
            <div style={{ width: "170px" }}>
              <FilledButton
                disabled={searchParams.get("serviceName") !== "MovieColab"}
                icon={<Plus />}
                submit={() => {
                  if (
                    orgServices[0] &&
                    (orgServices[0]?.subscription_exists
                      ?.monthly_subscription ||
                      orgServices[0]?.subscription_exists?.yearly_subscription)
                  ) {
                    handleAddLicenseModal();
                  } else {
                    history.push(
                      `/organizations/${params.orgId}/services/${params.sid}/payment`
                    );
                  }
                }}
                text="Buy License"
              />
            </div>
          </div>
          {currentValue === "active" && (
            <ActiveLicensesTable
              licenseSearchValue={licenseSearchValue}
              getOrgServices={getOrgServices}
            />
          )}
          {currentValue === "available" && (
            <AvailableLicensesTable
              licenseSearchValue={licenseSearchValue}
              getOrgServices={getOrgServices}
            />
          )}
          {currentValue === "expired" && (
            <ExpiredLicensesTable
              licenseSearchValue={licenseSearchValue}
              getOrgServices={getOrgServices}
            />
          )}
          {currentValue === "cancelled" && (
            <CancelledLicensesTable licenseSearchValue={licenseSearchValue} />
          )}
        </Grid>
      </Grid>
      <Modal
        open={isAddLicenseModalOpen}
        className={classes.centerContainer}
        onClose={handleAddLicenseModal}
      >
        <AddLicensesModal
          close={handleAddLicenseModal}
          activeLicenseCount={orgServices[0]?.license_stats?.used}
          availableLicenseCount={orgServices[0]?.license_stats?.available}
          subscriptionExists={orgServices[0]?.subscription_exists}
        />
      </Modal>
    </Box>
  );
};

export default LicensesOverview;
