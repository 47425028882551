import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Modal,
  Chip,
} from "@mui/material";
import { DotsThree, Info, Trash } from "phosphor-react";
import useStyles from "../LicensesOverview/LicensesOverview.styles";
import ReplaceUserIcon from "../../ReusableComponents/Svgs/ReplaceUserIcon";
import LicenseInfoModal from "../LicensePageModals/LicenseInfoModal";
import AddUserModal from "../LicensePageModals/AddUserModal";
import DeleteModal from "../../ReusableComponents/DeleteModal/DeleteModal";
import { fpatch } from "../../../API/callsApi";

const ActiveLicenseTableItem = ({
  eachLicense,
  handleRefreshCounter,
  licensedUsers,
  getOrgServices,
}) => {
  const classes = useStyles();
  const [activeLicenseToolsModal, setActiveLicenseToolsModal] = useState(null);
  const [isLicenseInfoModal, setLicenseInfoModal] = useState(false);
  const [isReplaceUserModal, setReplaceUserModal] = useState(false);
  const [disableLicenseModal, setDisableLicenseModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const handleActiveLicenseToolsModal = (event) => {
    setActiveLicenseToolsModal(event.currentTarget);
  };

  const handleCloseActiveLicenseModal = () => {
    setActiveLicenseToolsModal(null);
  };

  const handleLicenseInfoModal = () => {
    setLicenseInfoModal(!isLicenseInfoModal);
  };

  const handleReplaceUserModal = () => {
    setReplaceUserModal(!isReplaceUserModal);
  };

  const handleDisableLicense = () => {
    setDisableLicenseModal(!disableLicenseModal);
  };

  const disableLicense = async () => {
    setIsRemoving(true);
    try {
      const res = await fpatch({
        url: `/license/${eachLicense.id}/`,
        data: {
          status: "cancelled",
        },
      });
      if (res.status === 200 || res.status === 201) {
        setIsRemoving(false);
        handleRefreshCounter();
        getOrgServices();
        handleDisableLicense();
      }
    } catch (error) {
      console.log(error);
      setIsRemoving(false);
    }
  };

  const assignLicense = async (selectedUser) => {
    try {
      const res = await fpatch({
        url: `/license/${eachLicense.id}/`,
        data: {
          user: selectedUser.id,
        },
      });
      if (res.status === 200 || res.status === 201) {
        handleRefreshCounter();
        getOrgServices();
        handleReplaceUserModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableRow
        key={eachLicense.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          component="th"
          scope="row"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
          sx={{ width: "12% !important" }}
        >
          <Typography variant="h5" fontWeight={600}>
            {eachLicense.license_number}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
          sx={{ width: "19% !important" }}
        >
          <Chip label={eachLicense.license_type} variant="outlined" />
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
          sx={{ width: "19% !important" }}
        >
          <Typography variant="h5">
            {eachLicense.user?.first_name} {eachLicense.user?.last_name}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
          sx={{ width: "19% !important" }}
        >
          <Typography variant="h5">
            {eachLicense.subscribed_on
              ? new Date(eachLicense.subscribed_on).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )
              : "--"}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
          sx={{ width: "19% !important" }}
        >
          <Typography variant="h5">
            {new Date(eachLicense.license_validity).toLocaleDateString(
              "en-US",
              {
                month: "long",
                day: "numeric",
                year: "numeric",
              }
            )}
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
          sx={{ width: "12% !important" }}
        >
          <Button
            style={{
              margin: "0px",
              padding: "5px",
              minWidth: "0px",
              textTransform: "none",
              marginRight: "45px",
            }}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleActiveLicenseToolsModal(event);
            }}
          >
            <DotsThree size={20} color="#000000" />
          </Button>
        </TableCell>
      </TableRow>
      <Popover
        open={activeLicenseToolsModal !== null}
        anchorEl={activeLicenseToolsModal}
        onClose={handleCloseActiveLicenseModal}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List
          style={{
            borderRadius: "10px",
            padding: "0px",
            height: "max-content",
            border: "1px solid #666666",
          }}
        >
          <ListItem button={true} onClick={handleLicenseInfoModal}>
            <ListItemIcon>
              <Info size={22} color="#000000" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" color="#000000">
                License Info
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem button={true} onClick={handleReplaceUserModal}>
            <ListItemIcon>
              <ReplaceUserIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" color="#000000">
                Replace User
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem button={true} onClick={handleDisableLicense}>
            <ListItemIcon>
              <Trash size={22} color="#E01717" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" color="#E01717">
                Disable License
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
      <Modal
        open={isLicenseInfoModal}
        className={classes.centerContainer}
        onClose={handleLicenseInfoModal}
      >
        <LicenseInfoModal
          closeModal={handleLicenseInfoModal}
          licenseInfo={eachLicense}
        />
      </Modal>
      <Modal
        open={isReplaceUserModal}
        className={classes.centerContainer}
        onClose={handleReplaceUserModal}
      >
        <AddUserModal
          licensedUsers={licensedUsers}
          closeModal={handleReplaceUserModal}
          assignLicense={assignLicense}
          type="replace"
        />
      </Modal>
      <Modal
        className={classes.centerContainer}
        open={disableLicenseModal}
        onClose={handleDisableLicense}
      >
        <DeleteModal
          isRemoving={isRemoving}
          license={true}
          handleDelete={disableLicense}
          closeModal={handleDisableLicense}
          btnText="Disable License"
          text1="Are you sure you want to disable the following license?"
          text2={
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "24px",
                }}
              >
                <Typography variant="h4">License ID</Typography>{" "}
                <Typography variant="h4">:</Typography>{" "}
                <Typography variant="h4">
                  {eachLicense.license_number}
                </Typography>
              </div>
              <Typography
                sx={{ fontSize: "12px !important" }}
                mt={2}
                color="#E01717"
              >
                The assigned user will lose access and data will be wiped . This
                action can’t be undone so please be certain.
              </Typography>
            </div>
          }
        />
      </Modal>
    </>
  );
};

export default ActiveLicenseTableItem;
