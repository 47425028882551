import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { UserPlus, X } from "phosphor-react";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import ReplaceUserIcon from "../../ReusableComponents/Svgs/ReplaceUserIcon";
import useAuth from "../../../hooks/useAuth";
import { fget } from "../../../API/callsApi";

const AddUserModal = ({ closeModal, licensedUsers, assignLicense, type }) => {
  const { currentOrg } = useAuth();
  const [users, setUsers] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const getActiveUser = async () => {
    const url = `/organization/${currentOrg.id}/users/`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        const filteredData = res.data.filter((user) => {
          if (!licensedUsers.includes(user.id)) {
            return user;
          }
        });
        setUsers(filteredData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActiveUser();
  }, []);
  return (
    <Box
      sx={{
        borderRadius: "10px",
        backgroundColor: "#FFFFFF",
        width: "600px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 4,
        gap: "30px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {type === "replace" ? (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <ReplaceUserIcon />
            <Typography fontWeight={600} variant="h2">
              Replace User
            </Typography>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <UserPlus size={30} />{" "}
            <Typography fontWeight={600} variant="h2">
              Add User
            </Typography>
          </div>
        )}
        <Button onClick={closeModal}>
          <X size={30} color="#000000" />
        </Button>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Autocomplete
          value={selectedUser}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
          }}
          inputValue={textInput}
          onInputChange={(event, newInputValue) => {
            setTextInput(newInputValue);
          }}
          id="controllable-states-demo"
          options={users}
          getOptionLabel={(option) => {
            return `${option.first_name} ${option.last_name}`;
          }}
          fullWidth
          renderInput={(params) => <TextField {...params} label="User Name" />}
        />
      </div>
      {type === "replace" && (
        <Typography sx={{ fontSize: "12px !important" }} mt={1} color="#E01717">
          The previously assigned user will lose access to the service on
          replacement. This action can’t be undone so please be certain.
        </Typography>
      )}
      <div style={{ width: "200px" }}>
        <FilledButton
          disabled={!selectedUser}
          text="Save Changes"
          submit={() => assignLicense(selectedUser)}
        />
      </div>
    </Box>
  );
};

export default AddUserModal;
