import React from "react";
import { Box, Typography } from "@mui/material";
import TopBanner from "../../assets/TopBanner.png";
import Navbar from "../NewNavbar/Navbar";

const ServicePageBanner = ({ children }) => {
  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        boxSizing: "border-box !important",
        overflow: "hidden",
      }}
    >
      <Box sx={{ height: "34%" }}>
        <div style={{ height: "29%" }}>
          <Navbar servicePage={true} />
        </div>
        <Box
          style={{
            height: "71%",
            position: "relative",
          }}
        >
          <Box display="flex" style={{ height: "100%" }}>
            <Box
              p={4}
              style={{
                width: "50%",
                height: "100%",
                backgroundImage: `url(${TopBanner})`,
                backgroundSize: "100% 100%",
                color: "#FFFFFF",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">Movie Colab</Typography>
              <Typography variant="h6">
                Collaboration Software for Virtual Production
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "30px",
                }}
              >
                <a
                  href="https://www.youtube.com/watch?v=nPAHDhtUDL0"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    textTransform: "none",
                    padding: "10px 32px",
                    textDecoration: "none",
                  }}
                >
                  Watch Demo
                </a>
              </div>
            </Box>
            <Box
              style={{
                width: "50%",
                height: "100%",
              }}
            >
              <iframe
                style={{ border: "none" }}
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/nPAHDhtUDL0?autoplay=1&mute=1&loop=1&controls=0"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          height: "65%",
          boxSizing: "border-box",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ServicePageBanner;
