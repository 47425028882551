import React from "react";

const CheckCircleSvg = (small) => {
  return (
    <svg
      width={small ? "20" : "36"}
      height={small ? "20" : "36"}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5 18L30.84 13.83L31.35 8.31L25.935 7.08L23.1 2.31L18 4.5L12.9 2.31L10.065 7.08L4.65 8.295L5.16 13.815L1.5 18L5.16 22.17L4.65 27.705L10.065 28.935L12.9 33.705L18 31.5L23.1 33.69L25.935 28.92L31.35 27.69L30.84 22.17L34.5 18ZM15 25.5L9 19.5L11.115 17.385L15 21.255L24.885 11.37L27 13.5L15 25.5Z"
        fill={small ? "#000000" : "#00990A"}
      />
    </svg>
  );
};

export default CheckCircleSvg;
