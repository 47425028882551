import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Modal,
  Tooltip,
} from "@mui/material";
import useStyles from "../ManageTransactionAndInvoicesOverview/ManageTransactionAndInvoicesOverview.styles";
import { DotsThree, Info } from "phosphor-react";
import { Download, Report } from "@mui/icons-material";
import TransactionInfoModal from "../TransactionModals/TransactionInfoModal";
import ReportTransactionModal from "../TransactionModals/ReportTransactionModal";

const TransactionSuccessfulTableItem = ({ eachTransaction }) => {
  const classes = useStyles();
  const [transactionToolsModal, setTransactionToolsModal] = useState(null);
  const [isTransactionInfoModal, setTransactionInfoModal] = useState(false);
  const [reportTransactionModal, setReportTransactionModal] = useState(false);

  const handleTransactionInfoModal = () => {
    setTransactionInfoModal(!isTransactionInfoModal);
  };

  const handleReportTransactionModal = () => {
    setReportTransactionModal(!reportTransactionModal);
  };

  const openTransactionToolsModal = (event) => {
    setTransactionToolsModal(event.currentTarget);
  };
  const closeTransactionToolsModal = () => {
    setTransactionToolsModal(null);
  };
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell
          component="th"
          scope="row"
          className={classes.tableRowHeadService}
          sx={{ width: "17.6% !important" }}
        >
          <Tooltip title={eachTransaction.checkout_id}>
            <Typography
              variant="h5"
              fontWeight={500}
              style={{
                width: "150px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {eachTransaction.checkout_id}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
          sx={{ width: "17.6% !important" }}
        >
          <Typography variant="h5">
            {new Date(eachTransaction.created_at).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
          sx={{ width: "17% !important" }}
        >
          <Typography variant="h5">{eachTransaction.product_name}</Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
          sx={{ width: "17% !important" }}
        >
          <Typography variant="h5">
            {eachTransaction.licenses_data.length > 0
              ? eachTransaction.licenses_data[0]?.license_type
              : "--"}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
          sx={{ width: "18% !important" }}
        >
          <Typography variant="h5" textAlign="center">
            Rs. {eachTransaction.total_amount}
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          className={`${classes.tableRowHeadService}`}
          sx={{ width: "11% !important" }}
        >
          <Button
            style={{
              margin: "0px",
              padding: "5px",
              minWidth: "0px",
              textTransform: "none",
              marginRight: "40px",
            }}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              openTransactionToolsModal(event);
            }}
          >
            <DotsThree size={20} color="#666666" />
          </Button>
        </TableCell>
      </TableRow>
      <Popover
        open={transactionToolsModal !== null}
        anchorEl={transactionToolsModal}
        onClose={closeTransactionToolsModal}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List
          style={{
            borderRadius: "10px",
            padding: "0px",
            height: "max-content",
            border: "1px solid #666666",
          }}
        >
          <ListItem button={true} onClick={handleTransactionInfoModal}>
            <ListItemIcon>
              <Info size={22} color="#000000" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" color="#000000">
                Transaction Info
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem button={true}>
            <ListItemIcon>
              <Download style={{ color: "#000000" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" color="#000000">
                Download Receipt
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem button={true} onClick={handleReportTransactionModal}>
            <ListItemIcon>
              <Report style={{ color: "#E01717" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" color="#E01717">
                Report Transaction
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
      <Modal
        open={isTransactionInfoModal}
        className={classes.centerContainer}
        onClose={handleTransactionInfoModal}
      >
        <TransactionInfoModal
          closeModal={handleTransactionInfoModal}
          transactionInfo={eachTransaction}
        />
      </Modal>
      <Modal
        open={reportTransactionModal}
        className={classes.centerContainer}
        onClose={handleReportTransactionModal}
      >
        <ReportTransactionModal closeModal={handleReportTransactionModal} />
      </Modal>
    </>
  );
};

export default TransactionSuccessfulTableItem;
